<template>
  <div class="account-manage">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="text-left">活动报名记录</span>
      </div>
      <div class="activity-list" v-if="activityList.length > 0">
        <activityList
          :pageListTotal="pageListTotal"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :activityList="activityList"
          @change="costPlannedAmountChange($event)"
        ></activityList>
      </div>
      <div class="none-data" v-else>
        <img src="@/assets/pc/images/personal/none.png" alt="" />
        <span style="font-size: 14px; color: #b0b0b0">暂无内容</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { request } from "../../../network";
import activityList from "./components/activityList";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  components: {
    activityList,
  },
  data() {
    return {
      sizeForm: {
        type: "全部",
        date: "最新",
        input: "",
      },
      pageListTotal: 0,
      pageSize: 10,
      currentPage: 1,
      activityList: [],
    };
  },
  created() {},
  mounted() {
    this.getActivityList();
  },
  computed: {},
  methods: {
    async costPlannedAmountChange(value) {
      this.currentPage = value;
      this.getActivityList();
    },
    async getActivityList() {
      const { data: res } = await request(
        {
          method: "post",
          url: "/pcp/activity/getMemberActivityList",
          data: {
            memberId: getMemberId(),
            pageNo: this.currentPage,
            pageSize: this.pageSize,
          },
        },
        true
      );
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.activityList = res.data.list;
      this.pageListTotal = res.data.total;
    },
   
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.text-left {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.box-card {
  margin-bottom: 20px;
}
/deep/.el-card__body {
  padding: 0 20px;
}

.activity-list {
  margin: 20px 0;
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
</style>
